<template>
  <div  class="d-flex flex-column content ma-5 mb-16 px-0 px-sm-16 align-center" >


    <div class="text-h6 ma-8" v-if="waitting">登录中...</div>
    
    <v-card :class="$vuetify.breakpoint.mobile?'':'halfwidth'" v-if="!waitting">
      <v-card-title>
        <span class="text-h6">绑定账号</span>
      </v-card-title>
      <v-card-text>
        <v-container >
          <v-row>
            <v-col cols="12" class="pa-2">
              <v-text-field
                label="手机号"
                required
                v-model="mobile"
                class="mt-0 pt-0"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pa-2">
              <v-text-field
                label="验证码"
                v-model="code"
                
                required
                class="mt-0 pt-0"
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pa-2">
              <v-btn  dark outlined  tile class="main-btn" @click="sendcode">发送验证码</v-btn>
            </v-col>

            <v-col cols="12" class="pa-2">
              <v-btn  dark outlined  tile class="main-btn" @click="bind">确认</v-btn>
            </v-col>
    
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  


  </div>
</template>

<script>


import userApi from '@/api/user'
import { mapState } from 'vuex'

export default {



  data: () => ({
    waitting : true,
    mobile:"",
    code : "",
    userinfo : null
    //sumamount : 0,

    

  
    //
  }),
  computed: {
    ...mapState({
      domain: state => state.app.imgdomain,
    }),

  },
  created() {
    
    let code = this.$route.query.code
    if (code != undefined && code != null && code != "") {
      this.checkAccount(code)
    }
    
  },
  methods: {

    bind() {
      if (this.mobile.length != 11) {
        this.$message.warning('请输入11位手机号码')
        return
      }
      let type = this.$route.query.type
      let params = {type:type,mobile:this.mobile,checkcode : this.code,userinfo:JSON.stringify(this.userinfo)}
      let source_uid = this.$util.getSourceId()
      if (source_uid!=null && source_uid!=0) {
        params.source_uid = source_uid
      }

      userApi.bind(params).then(res => {
        if (res.message=='success') {
          if (res.data.uid > 0) {
            this.$util.setToken(res.data.token)
            let userinfo = {}
            userinfo.uid = res.data.uid
            userinfo.user_tel = res.data.user_tel
            userinfo.user_headimg = res.data.user_headimg
            userinfo.user_name = res.data.user_name
            userinfo.nick_name = res.data.nick_name
            //console.log(2,userinfo)
            //this.$store.commit("SET_USERINFO",this.userinfo)
            this.$store.dispatch("user/set_userinfo",userinfo)

            const sourceurl = this.$util.getSourceUrl()
            this.$util.clearSourceUrl()
            if (sourceurl) {
              
              location.href = sourceurl
            } else {
              this.$router.push("/profile")
            }

            //this.$router.push("/profile")
          }
        }
        console.log(res)
      })


    },
    checkAccount(code) {

      this.waitting = false
      let type = this.$route.query.type
      userApi.login3pcall({type:type,code:code}).then(res => {
        let status = res.data.status
        
        if (status=="logined") {

          this.$util.setToken(res.data.token)
          let userinfo = {}
          userinfo.uid = res.data.uid
          userinfo.user_tel = res.data.user_tel
          userinfo.user_headimg = res.data.user_headimg
          userinfo.user_name = res.data.user_name
          userinfo.nick_name = res.data.nick_name
          //console.log(2,userinfo)
          //this.$store.commit("SET_USERINFO",this.userinfo)
          this.$store.dispatch("user/set_userinfo",userinfo)

          const sourceurl = this.$util.getSourceUrl()
          this.$util.clearSourceUrl()
          if (sourceurl) {
            
            location.href = sourceurl
          } else {
            this.$router.push("/profile")
          }
          
        } else if (status=="bind") {
          let userinfo = res.data.userinfo
          this.userinfo = userinfo
        } else {
          console.log(res)
        }
        
      })
      
      
    },
    sendcode() {

      if (this.mobile.length != 11) {
        this.$message.warning('请输入11位手机号码')

        return
      }
      userApi.mobilecode({mobile:this.mobile}).then((data) => {
        if (data.data.code==0){
          console.log(data)
          this.showRegCode = true
        } else {
          console.log(data)
        }
        
        
      })
      //this.showRegCode = true
    },
    
  },
};
</script>
<style scoped>
.content {
  
  position: relative;
}  
.halfwidth {
  width:50%;
}

</style>
